import { CancelationReasonsDto, PagedResultOfProductsViewModel, ProductsCreateDto, ProductsDto, ProductsListViewModel, ProductsUpdateDto } from "@/api-client";
import { CancelationReasons, Products } from "@/network/api";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";

export function productsGet(
  pgNum: number = 1,
  pgSize: number = 20,
  ): Promise<PagedResultOfProductsViewModel> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Products.apiProductsGet(pgNum, pgSize)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function productsGetId(id: number): Promise<ProductsDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Products.apiProductsIdGet(id)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function productsPost(item: ProductsCreateDto): Promise<number> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Products.apiProductsPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Created!", showClose: true })
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function productsPut(id: number, item: ProductsUpdateDto): Promise<void> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Products.apiProductsIdPut(id, item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Updated!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function topUpDdlGet(): Promise<ProductsListViewModel[]> {
  return new Promise((resolve, reject) => {
    Products.apiProductsTopupListGet()
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data as ProductsListViewModel[])
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function migrateDdlGet(currentProductId?: number): Promise<ProductsListViewModel[]> {
  return new Promise((resolve, reject) => {
    Products.apiProductsMigrationListGet(currentProductId)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data as ProductsListViewModel[])
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function cancelDdlGet(): Promise<CancelationReasonsDto[]> {
  return new Promise((resolve, reject) => {
    CancelationReasons.apiCancelationreasonsGet()
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}