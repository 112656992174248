
import { Component, Vue } from "vue-property-decorator";
import { ProductsCreateDto, ProductsDto, ProductsUpdateDto } from "@/api-client";
import { handleInvalidForm } from "@/utils/auth";
import { Rule, ValidateFieldsError } from "@/types/async-validator";
import { productsGetId, productsPost, productsPut } from "@/api/products";
import store from "@/store";

type ProductType = "LTE" | "TOP" | "ROU"
interface ExtendedProductsCreateDto extends ProductsCreateDto {
  productType: ProductType
}
interface ExtendedProductsUpdateDto extends ProductsUpdateDto {
  productType: ProductType
}
interface ExtendedProductsDto extends ProductsDto {
  productType: ProductType
}

@Component({
  name: "ProductDetail",
  components: {},
  props: {
    id: { type: String },
    pageNumber: { type: String },
    pageSize: { type: String },
  },
})
export default class extends Vue {
  title = "";
  formData: ExtendedProductsCreateDto | ExtendedProductsUpdateDto = {
    id: 0,
    startDate: "",
    endDate: "",
    provider: "",
    productType: "LTE",
    providerDealId: "",
    providerPackageName: "",
    providerDealDescription: "",
    providerCost: 0,
    vascoDealId: "",
    vascoDealDescription: "",
    vascoPackageDescription: "",
    vascoPrice: 0,
    adminFee: 0,
    agentCommission: 0,
    dealerCommission: 0,
    contractPeriod: "24",
    isTopUp: false,
  };
  productTypes: ProductType[] = ["LTE", "TOP", "ROU"];

  async created() {
    this.loadInitialData(+this.$props.id);
  }

  get queries() {
    return {
      pageNumber: this.$props?.pageNumber,
      pageSize: this.$props?.pageSize,
    }
  }

  get rules(): Partial<Record<keyof typeof this.formData, Rule>> {
    return {
      startDate: { required: true, message: "Please enter a start date" },
      endDate: { required: true, message: "Please enter an end date" },
      provider: { required: true, message: "Please enter a provider" },
      productType: { required: true, message: "Please select a product type" },
      providerDealId: { required: true, message: "Please enter the provider deal ID" },
      providerPackageName: { required: true, message: "Please enter the provider package name" },
      providerDealDescription: { required: true, message: "Please enter the provider deal description" },
      providerCost: { required: true, type: "number", message: "Please enter the provider cost" },
      vascoDealId: { required: true, message: "Please enter a vasco deal ID" },
      vascoDealDescription: { required: true, message: "Please enter a vasco package name" },
      vascoPackageDescription: { required: true, message: "Please enter a vasco deal description" },
      vascoPrice: { required: true, type: "number", message: "Please enter the vasco price" },
      adminFee: { required: true, type: "number", message: "Please enter the admin fee" },
      agentCommission: { required: true, type: "number", message: "Please enter agent commission" },
      dealerCommission: { required: true, type: "number", message: "Please enter dealer commission" },
    };
  }

  submitForm(formName: string) {
    (this.$refs[formName] as any)?.validate((valid: boolean, fields: ValidateFieldsError) => {
      if (valid) {
        this.formData.isTopUp = this.formData.productType == "TOP"
        if ((this.formData as ProductsUpdateDto).id == 0) {
          productsPost(this.formData as ProductsCreateDto).then(async (res) => {
            this.$router.replace({ name: "ProductDetail", params: { id: res.toString() } });
            await this.loadInitialData(res);
          });
        } else {
          productsPut((this.formData as ProductsUpdateDto).id, this.formData as ProductsUpdateDto).then(async () => {
            await this.loadInitialData((this.formData as ProductsUpdateDto).id);
          });
        }
      } else {
        handleInvalidForm(fields as ValidateFieldsError);
      }
    });
  }

  async loadInitialData(id: number): Promise<any> {
    if (id) {
      await productsGetId(id).then(async (res) => {
        this.title = res.vascoDealId;
        this.formData = res as ExtendedProductsDto;

        await store.dispatch("user/addRecentlyViewed", {
          id: id,
          displayName: this.title || "",
          type: "Product"
        })
      });
    }
  }
}
